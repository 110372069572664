<template>
    <div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column label="评价人昵称" align="center" prop="userName" width="200">
            </el-table-column>
            <el-table-column label="被评价人昵称" align="center" prop="tevaluationUserName" width="200">
            </el-table-column>
            <el-table-column label="评价分数" align="center" prop="evaluationScore" width="100">
            </el-table-column>
            <el-table-column label="评价标签" align="center" prop="evaluationLabel" width="300">
            </el-table-column>
            <el-table-column label="评价内容" align="center" prop="evaluationContent" min-width="300">
            </el-table-column>

            <el-table-column prop="evaluationScore" align="center" label="评分" width="200">
                <template slot-scope="scope">
                    <el-rate v-model="scope.row.evaluationScore" disabled></el-rate>
                </template>
            </el-table-column>
            <el-table-column prop="createdTime" align="center" width="200" label="评价时间">
            </el-table-column>
            <el-table-column prop="icon" align="center" width="160" fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="evaluationButton(scope.row.tevaluationId)">审核</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="current"
            :page-size="size" :total="total">
        </el-pagination>
        <EvaluationAudit ref="evaluationAudit" v-if="evaluationAuditvisible" @refreshDataList="getEvaluationList">
        </EvaluationAudit>
    </div>
</template>
<script>
import EvaluationAudit from "./EvaluationAudit";
export default {
    name: "Evaluation",
    components: {
        EvaluationAudit,
    },
    data() {
        return {
            searchForm: {},
            delBtlStatu: true,
            evaluationAuditvisible: false,
            tableData: [],
            multipleSelection: [],
            total: 0,
            size: 10,
            current: 1,
        }
    },
    created() {
        this.getEvaluationList();
    },
    mounted() {
        // 判断浏览器是否是火狐
        if (navigator.userAgent.indexOf("Firefox") > 0) {
            this.isFirefox = true;
        }
    },
    methods: {
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.delBtlStatu = val.length == 0
        },

        handleSizeChange(val) {
            this.size = val
            this.getEvaluationList()
        },
        handleCurrentChange(val) {
            this.current = val
            this.getEvaluationList()
        },
        evaluationButton(id) {
            this.evaluationAuditvisible = true;
            this.$nextTick(() => {
                this.$refs.evaluationAudit.init(id);
            })
        },

        //获取标签类型列表
        getEvaluationList() {
            this.searchForm.pageNo = this.current
            this.searchForm.pageSize = this.size
            this.$axios.post("/admin/evaluation/getEvaluationList", this.searchForm).then(res => {
                this.tableData = res.data.records
                this.size = res.data.size
                this.current = res.data.pages
                this.total = res.data.total
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>
